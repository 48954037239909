import { ApolloError } from '@apollo/client';
import { Location, Path } from 'react-router-dom';
import { EmptyObject, Get, Paths, UnknownRecord } from 'type-fest';

export type Undefinable<A> = A | undefined;

export type Nullable<A> = A | null;

export type Nilable<A> = A | Nil;

export type NotNilable<A> = Exclude<A, Nil>;

export type Nil = null | undefined;

export type NonEmptyArray<T> = [T, ...T[]];

export type NilableArray<T> = Nilable<Nilable<T>[]>;

/**
 * Utility type to retrieve the type of a deeply nested object property, regardless if its ancestors are nullable.
 * <br/>
 * <b>Warning:</b> This type is recursive and will not work for excessively deep property paths or circular references.
 * <br/> You maybe need to use nested NonNullable definitions if you run into the following errors:
 * <br/>
 * <i>TS2589: Type instantiation is excessively deep and possibly infinite.</i>
 * <br/>
 * <i>TS2615: Type of property 'XYZ' circularly references itself in mapped type</i>
 */
export type NestedNonNullable<T, P extends Paths<T> & string> = NonNullable<Get<T, P>>;

export const isNull = (x: unknown): x is null => x === null;

export const isNotNull = <T>(x: Nullable<T>): x is Exclude<T, null> => !isNull(x);

export const isUndefined = (x: unknown): x is undefined => x === undefined;

export const isNotUndefined = <T>(x: Undefinable<T>): x is Exclude<T, undefined> => !isUndefined(x);

export const isNil = (x: unknown): x is Nil => isNull(x) || isUndefined(x);

export const isNotNil = <T>(x: Nilable<T>): x is T => !isNil(x);

export const isString = (x: unknown): x is string => typeof x === 'string';

export const isEmptyString = (x: unknown): x is '' => isString(x) && x === '';

export const isNonEmptyString = (x: unknown): x is string => isString(x) && !isEmptyString(x);

export const isNumber = (x: unknown): x is number => typeof x === 'number';

export const isNaN = (x: unknown): x is number => Number.isNaN(x);

export const isInteger = (x: unknown): x is number => Number.isInteger(x);

export const isNonNaNNumber = (x: unknown): x is number => isNumber(x) && !isNaN(x);

export const isRecord = (x: unknown): x is EmptyObject | Record<string, unknown> =>
  isNotNil(x) && typeof x === 'object' && !(x instanceof Array);

export const isEmptyRecord = (x: unknown): x is EmptyObject =>
  isRecord(x) && Object.keys(x).length === 0;

export const isNonEmptyRecord = (x: unknown): x is Record<string, unknown> =>
  isRecord(x) && Object.keys(x).length > 0;

export const isArray = (x: unknown): x is unknown[] =>
  isNotNil(x) && typeof x === 'object' && x instanceof Array;

export const isEmptyArray = (x: unknown): x is never[] => isArray(x) && x.length === 0;

export const isNonEmptyArray = <T>(x: Nilable<T[] | UnknownRecord>): x is NonEmptyArray<T> =>
  isArray(x) && x.length > 0;

export const isFunction = (x: unknown): x is (...args: unknown[]) => unknown =>
  isNotNil(x) && typeof x === 'function';

export const isBoolean = (x: unknown): x is boolean => isNotNil(x) && typeof x === 'boolean';

// eslint-disable-next-line no-void
export const isVoid = (x: unknown): x is void => x === void 0;

export const isError = (error: unknown): error is Error => {
  return isNotNil(error) && error instanceof Error;
};

export const isApolloError = (error: unknown): error is ApolloError => {
  return isError(error) && error instanceof ApolloError;
};

export const isHtmlElement = (x: unknown): x is HTMLElement => {
  return x instanceof HTMLElement;
};

/**
 * Curried function that tests if an input is an object
 * _and_ that a key that was passed in is a property of that object
 *
 * @param key - The key to test if it is a property of the input record
 * @returns A function that takes an unknown input and then tests if the
 *  input is an object and whether the key is a property of that object or not
 */

export const hasKey = <Key extends string>(x: unknown, key: Key): x is { [K in Key]: unknown } =>
  isNonEmptyRecord(x) && key in x;

export const readonlyArrayIncludes = <T>(arr: readonly T[], elem: unknown): elem is T => {
  return arr.includes(elem as T);
};

export const isReactRouterPath = (obj: unknown): obj is Path => {
  return (
    typeof obj === 'object' && obj !== null && 'pathname' in obj && 'search' in obj && 'hash' in obj
  );
};

export const isReactRouterLocation = (obj: unknown): obj is Location => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'key' in obj &&
    'state' in obj &&
    isReactRouterPath(obj)
  );
};

export const hasFromLocation = (obj: unknown): obj is { from: Location } => {
  return (
    typeof obj === 'object' && obj !== null && 'from' in obj && isReactRouterLocation(obj.from)
  );
};

export const isOfType =
  <
    GenericType extends string,
    Union extends { type: GenericType },
    SpecificType extends GenericType,
  >(
    val: SpecificType,
  ) =>
  (obj: Union): obj is Extract<Union, { type: SpecificType }> =>
    obj.type === val;

export const isMutableRefObject = <T>(x: unknown): x is React.MutableRefObject<T> => {
  return isNotNil(x) && isNonEmptyRecord(x) && 'current' in x;
};
